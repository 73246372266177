import {Store} from "react-stores";

export interface IStoreState {
    locale: string;
}

export const localeStore = new Store<IStoreState>({locale: navigator.language}, {
    persistence: true,
    name: "localeStore"
});
