import React, {useEffect, useMemo, useState} from "react";
import {useStore} from "react-stores";
import {store} from "../shared/stores/store";
import {BottomMenu} from "../components/BottomMenu";
import {FormattedMessage} from "react-intl";
import {motion} from "framer-motion";
import Gallery, {PhotoProps} from "react-photo-gallery";
import {FeedPhoto} from "../components/FeedPhoto";
import {PhotoSession} from "../shared/models/models";
import {ScreenSize} from "../misc";
import {useScreenSize} from "../hooks/useScreenSize";

const photoHeight = 500;
const gap = 0;

export const Home: React.FC = () => {
    const {sections} = useStore(store);
    const [session, setSession] = useState<PhotoSession>();
    const [images, setImages] = useState<PhotoProps[]>([]);

    const screenSize = useScreenSize();

    useEffect(() => {
        sections.forEach((s) => {
            if (s.sectionType === "mainpage") {
                const session = s.sessions[0];
                const photos: PhotoProps[] = [];
                session.photos?.forEach((r) => {
                    photos.push({src: r.url, width: r.width, height: r.height});
                });
                setImages(photos);
                setSession(session);
            }
        });
    }, [sections]);

    const numOfColumns = useMemo(() => {
        switch (screenSize) {
            case ScreenSize.Mobile: {
                return 3;
            }
            case ScreenSize.Large: {
                return 5;
            }
            default:
            case ScreenSize.Small: {
                return 3;
            }
        }
    }, [screenSize])

    return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{paddingInlineStart: gap, paddingInlineEnd: gap}}>
            {session ? <Gallery margin={gap} columns={numOfColumns} direction={"column"} photos={images}
                                renderImage={(p) => {
                                    return <div key={p.photo.src}
                                                style={{position: "absolute", top: p.top, left: p.left! + gap}}>
                                        <FeedPhoto
                                            height={p.photo.height}
                                            sessionId={session.id!} photo={{
                                            url: p.photo.src,
                                            height: p.photo.height,
                                            width: p.photo.width,
                                            orderIndex: p.index,
                                            uploaded: true
                                        }}/></div>
                                }
                                }/> : null}
        </div>
        {session !== undefined ? <div style={{paddingBottom: "3vh", paddingTop: "3vh", alignSelf: 'center'}}>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .2}}
                className={"text-main"} style={{paddingLeft: "3vw", paddingRight: "3vw"}}>
                <FormattedMessage
                    id="HOME_DESCRIPTION"
                />
            </motion.div>
        </div> : null}
        {session !== undefined ? <BottomMenu/> : null}
    </div>
}
