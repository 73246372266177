import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {collection, getDocs, getFirestore, addDoc, setDoc, doc, getDoc} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {allSections, Photo, PhotoSession} from "../models/models";
import {ISectionStorage, IStoreState, store} from "../stores/store";
import {deleteObject} from "@firebase/storage";
import {deleteDoc} from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAWTAp2Y7JfJcxYhzD4NtKKjQ44VZHmOUc",
    authDomain: "mariajanestudio-2bf05.firebaseapp.com",
    projectId: "mariajanestudio-2bf05",
    storageBucket: "mariajanestudio-2bf05.appspot.com",
    messagingSenderId: "16992498796",
    appId: "1:16992498796:web:0b7954b1816c63c5db9449",
    measurementId: "G-JJ2TYSVRMZ"
};

export class DataManager {
    public app = initializeApp(firebaseConfig);
    public analytics = getAnalytics(this.app);
    public db = getFirestore(this.app);
    public storage = getStorage(this.app);

    public refreshPhotoSets() {
        return new Promise<void>((resolve, reject) => {
            const sections: ISectionStorage[] = [];
            allSections.forEach((section) => {
                getDocs(collection(this.db, section)).then((sectionResult) => {
                    const sessions: PhotoSession[] = [];

                    const docsLength = sectionResult.docs.length;

                    if (docsLength) {
                        sectionResult.docs.forEach((sessionResult) => {
                            this.getSessionWithPhotos(sessionResult, section).then((photoSession) => {
                                sessions.push(photoSession);

                                if (sessions.length === docsLength) {
                                    sections.push({sectionType: section, sessions: sessions});
                                    if (sections.length === allSections.length) {
                                        store.setState({sections});
                                        resolve();
                                    }
                                }
                            }).then();
                        });
                    } else {
                        sections.push({sectionType: section, sessions: []});
                        if (sections.length === allSections.length) {
                            store.setState({sections});
                            resolve();
                        }
                    }
                }).then();
            });
        }).then();
    };

    public refreshSession(session: PhotoSession) {
        return new Promise<PhotoSession>((resolve, reject) => {
            getDoc(doc(this.db, `${session.section}/${session.id}`)).then((r) => {
                this.getSessionWithPhotos(r, session.section!).then((r) => {
                    if (session.section) {
                        const state = store.state;

                        const section: string = session.section;

                        let currentSectionStorage: ISectionStorage | null = null;

                        state.sections.forEach((sectionStorage) => {
                            if (sectionStorage.sectionType === session.section) {
                                currentSectionStorage = sectionStorage;
                                currentSectionStorage.sessions.forEach((session, index) => {
                                    if (session.id === r.id) {
                                        currentSectionStorage!.sessions[index] = r;
                                    }
                                });
                            }
                        });

                        store.setState({...state});

                        resolve(r);
                        // const currentSection: PhotoSession[] = [...state[section as keyof IStoreState]];
                        //
                        // currentSection.forEach((session, index) => {
                        //     if (session.id === r.id) {
                        //         currentSection[index] = r;
                        //     }
                        // });
                        //
                        //
                        // state[section as keyof IStoreState] = currentSection;
                        //
                        // store.setState(state);
                        //
                        // resolve(r);
                    }
                }).then();
            }).then();
        }).then();
    }

    private getSessionWithPhotos(sessionResult: any, section: string): Promise<PhotoSession> {
        return new Promise<PhotoSession>((resolve, reject) => {
            getDocs(collection(this.db, `${section}/${sessionResult.id}/photos`)).then((photosResult) => {
                let photos: Photo[] = [];

                photosResult.docs.forEach((photoResult, index) => {
                    photos.push({
                        id: photoResult.id,
                        height: photoResult.data().height,
                        width: photoResult.data().width,
                        uploaded: true,
                        url: photoResult.data().url,
                        orderIndex: photoResult.data().orderIndex,
                        fileName: photoResult.data().fileName,
                        cover: photoResult.data().cover
                    });
                });

                photos = this.sortPhotos(photos);

                const session = {
                    section: section,
                    name: sessionResult.data().name,
                    info: sessionResult.data().info,
                    id: sessionResult.id,
                    photos: photos
                };

                resolve(session);
            }).then();
        }).then();
    }

    public sortPhotos(photos: Photo[]): Photo[] {
        return photos.sort((a, b) => {
            return a.orderIndex - b.orderIndex;
        });
    }

    public addPhotoSet(section?: string, name?: string, info?: string) {
        const set: PhotoSession = {
            name: name ? name : "",
            info: info ? info : "",
        }

        addDoc(collection(this.db, section!), set).then(() => {
            this.refreshPhotoSets().then();
        });
    }

    public updatePhotoSet(id: string, section?: string, name?: string, info?: string) {
        const set: PhotoSession = {
            name: name ? name : "",
            info: info ? info : "",
        }

        setDoc(doc(this.db, section!, id), set, {merge: true}).then(() => {
            this.refreshPhotoSets().then();
        });
    }

    public setPhotosForPhotoSet(set: PhotoSession, photo: Photo[]) {

    }

    public uploadImagesForPhotoSet(photos: Photo[], set: PhotoSession): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const photosCopy: Photo[] = JSON.parse(JSON.stringify(photos));

            let uploadedPhotosCount = 0;

            photosCopy.forEach((photo) => {
                const storageRef = ref(this.storage, `${set.section}/${set.id}/${photo.fileName}`);
                fetch(photo.url).then((r) => {
                    r.blob().then((r) => {
                        uploadBytes(storageRef, r).then((snapshot) => {
                            getDownloadURL(snapshot.ref).then((r) => {
                                photo.url = r;
                                photo.uploaded = true;

                                uploadedPhotosCount++;

                                if (uploadedPhotosCount === photosCopy.length) {
                                    const promises: Promise<any>[] = [];

                                    photosCopy.forEach((photo) => {
                                        const promise = addDoc(collection(this.db, `${set.section}/${set.id}/photos`), photo).then(() => {

                                        });

                                        promises.push(promise);
                                    });

                                    Promise.all(promises).then(() => {
                                        resolve();
                                    });
                                }
                            });
                        });
                    })
                });
            });
        });
    }

    public removePhoto(set: PhotoSession, photo: Photo) {
        return new Promise<void>((resolve, reject) => {
            deleteObject(ref(this.storage, `${set.section}/${set.id}/${photo.fileName}`)).then(() => {
                console.log('id = ', photo.id);
                deleteDoc(doc(this.db, `${set.section}/${set.id}/photos/${photo.id}`)).then(() => {
                    resolve();
                }).then();
            }).catch(() => {
                deleteDoc(doc(this.db, `${set.section}/${set.id}/photos/${photo.id}`)).then(() => {
                    resolve();
                }).then();
            }).then();
        });
    }

    public movePhotoUp(photo: Photo, photos: Photo[]): Photo[] | null {
        if (photo.orderIndex !== 0) {
            this.arrayMove(photos, photo.orderIndex, photo.orderIndex - 1);
            this.actualizeOrderIndexes(photos);
            return photos;
        }
        return null;
    }

    public movePhotoDown(photo: Photo, photos: Photo[]): Photo[] | null {
        console.log('movePhotoDown')
        if (photo.orderIndex < photos.length - 1) {
            this.arrayMove(photos, photo.orderIndex, photo.orderIndex + 1);
            this.actualizeOrderIndexes(photos);
            return photos;
        }
        return null;
    }

    public makePhotoCover(photo: Photo, photos: Photo[]): Photo[] | null {
        photos.forEach((r, index) => {
            if (r.id === photo.id) {
                r.cover = true;
            } else {
                r.cover = false;
            }
        });
        return photos;
    }

    private arrayMove(photos: Photo[], fromIndex: number, toIndex: number) {
        const element = photos[fromIndex];
        photos.splice(fromIndex, 1);
        photos.splice(toIndex, 0, element);
    }

    private actualizeOrderIndexes(photos: Photo[]) {
        photos.forEach((r, index) => {
            r.orderIndex = index;
        });
    }

    public updatePhotos(set: PhotoSession, photos: Photo[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const promises: Promise<any>[] = [];

            photos.forEach((r) => {
                const promise = setDoc(doc(this.db, `${set.section}/${set.id}/photos/${r.id}`), r, {merge: true}).then(() => {
                    this.refreshPhotoSets().then();
                    console.log('updated');
                }).then();

                promises.push(promise);
            });

            Promise.all(promises).then(() => {
                resolve();
            }).then();
        });
    }
}
