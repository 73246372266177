import React from "react";
import {motion} from "framer-motion";
import {FormattedMessage} from "react-intl";
import {BottomMenu} from "../components/BottomMenu";
import {AiOutlinePhone, AiOutlineInstagram, AiOutlineMail, AiOutlineWhatsApp, AiOutlineFacebook} from 'react-icons/ai';
import {BiLogoPinterestAlt} from "react-icons/bi";


export const Contacts: React.FC = () => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "space-between",
        paddingInlineStart: "5vw",
        paddingInlineEnd: "5vw",
        height: '100%'
    }}>
        <div style={{
            paddingBottom: "3vh",
            paddingTop: "3vh",
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 760,
        }}>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .2}}
                className={"text-about-title"}>
                <FormattedMessage
                    id="CONTACTS_TITLE"
                />
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .3}}
                style={{paddingTop: "1vh"}} className={"text-about-body"}>
                <FormattedMessage
                    id="CONTACTS_BODY"
                />
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .4}}
                style={{paddingTop: "2vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <AiOutlineMail size={20}/>
                <a className={"text-contacts-link"}
                   href={"mailto:mariajanestudio@gmail.com"}>{"mariajanestudio@gmail.com"}</a>
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .5}}
                style={{paddingTop: "1vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <AiOutlineInstagram size={20}/>
                <a className={"text-contacts-link"}
                   href={"https://www.instagram.com/mariajanestudio/"}>{"@mariajanestudio"}</a>
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .6}}
                style={{paddingTop: "1vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <AiOutlinePhone size={20}/>
                <a className={"text-contacts-link"} href={"tel:+357 97 686964"}>{"+357 97 686964"}</a>
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .7}}
                style={{paddingTop: "1vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <AiOutlineWhatsApp size={20}/>
                <a className={"text-contacts-link"}
                   href="https://api.whatsapp.com/send?phone=35797686964">{"WhatsApp"}</a>
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .8}}
                style={{paddingTop: "1vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <AiOutlineFacebook size={20}/>
                <a className={"text-contacts-link"}
                   href="https://www.facebook.com/mariajanestudio">{"Facebook"}</a>
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .9}}
                style={{paddingTop: "1vh", alignItems: 'center', display: "flex"}} className={"text-about-body"}>
                <BiLogoPinterestAlt size={20}/>
                <a className={"text-contacts-link"}
                   href="https://www.pinterest.com/mariajanestudio">{"Pinterest"}</a>
            </motion.div>
        </div>
        <BottomMenu/>
    </div>
}
