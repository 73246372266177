import React from "react";
import {useScreenSize} from "../hooks/useScreenSize";
import {ScreenSize} from "../misc";

export const Separator: React.FC = () => {
    const size = useScreenSize();
    return <div className={'text-bottom-menu'} style={{
        paddingLeft: size === ScreenSize.Mobile ? 8 : 10,
        paddingRight: size === ScreenSize.Mobile ? 8 : 10
    }}>|</div>
}
