import React, {useCallback, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";
import {ISectionStorage, store} from "../shared/stores/store";
import {useStore} from "react-stores";
import {FormattedMessage} from "react-intl";
import {useScreenSize} from "../hooks/useScreenSize";
import {ScreenSize} from "../misc";
import Gallery, {PhotoProps, RenderImageProps} from "react-photo-gallery";
import {FeedPhoto} from "./FeedPhoto";
import {Link} from "react-router-dom";
import {Photo, PhotoSession} from "../shared/models/models";

const Color = require('color');

export interface IProps {
    menuHeight: number;
    onClose: () => void;
}

export interface ICustomPhotoProps extends PhotoProps {
    session: PhotoSession
}

const gap = 8;

export const SessionsSelectionMenu: React.FC<IProps> = (props) => {
    const {menuHeight, onClose} = props;
    const {sections} = useStore(store);
    const screenSize = useScreenSize();
    const [selectedSection, setSelectedSection] = useState<ISectionStorage>();

    useEffect(() => {
        sections.forEach((s) => {
            if (s.sectionType === "weddings") {
                setSelectedSection(s);
            }
        });
    }, [sections]);

    const numOfColumns = useMemo(() => {
        switch (screenSize) {
            case ScreenSize.Mobile: {
                return 1;
            }
            case ScreenSize.Large: {
                return 3;
            }
            default:
            case ScreenSize.Small: {
                return 3;
            }
        }
    }, [screenSize])

    const weddingsLinks = useMemo(() => {
        if (!selectedSection) {
            return <div/>;
        }
        const sessions = selectedSection.sessions;
        const images: ICustomPhotoProps[] = [];

        sessions.forEach((session) => {
            if (session.photos?.length) {
                let photo = session.photos[0];
                session.photos.forEach((p) => {
                    if (p.cover) {
                        photo = p;
                    }
                })
                images.push({src: photo.url, width: photo.width, height: photo.height, session: session});
            }
        });

        return <div
            key={`section_container_${selectedSection.sectionType}`}
            style={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
            }}>
            {
                images.length ?
                    <Gallery key={`gallery_${selectedSection.sectionType}`} margin={gap} columns={numOfColumns}
                             direction={"column"}
                             photos={images}
                             renderImage={(p: RenderImageProps<any>) => {
                                 return <div key={p.photo.src}
                                             style={{position: "absolute", top: p.top, left: p.left! + gap}}>
                                     <Link className={"text-selection-menu"}
                                           onClick={
                                               () => {
                                                   onClose();
                                               }
                                           }
                                           to={`/session/${p.photo.session!.section}/${p.photo.session.id}`}
                                     >
                                         <FeedPhoto
                                             height={p.photo.height}
                                             sessionId={'12'!}
                                             title={p.photo.session.name}
                                             photo={{
                                                 url: p.photo.src,
                                                 height: p.photo.height,
                                                 width: p.photo.width,
                                                 orderIndex: p.index,
                                                 uploaded: true
                                             }}/>
                                     </Link>
                                 </div>
                             }
                             }/> : null
            }

        </div>
    }, [selectedSection, menuHeight, numOfColumns]);

    const menuSection = useCallback((section: ISectionStorage, index: number) => {
        const selected = section.sectionType == selectedSection?.sectionType;

        return <motion.div
            onClick={() => {
                setSelectedSection(section);
            }}
            key={`motion_${section.sectionType}`}
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{ease: "circOut", duration: 0.15, delay: 0.2 + 0.03 * index}}
            style={{
                display: "flex",
                flexGrow: 1,
                background: `var(--background)`,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: selected ? 'var(--accent)' : `var(--background)`,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <div className={"text-selection-menu-title"} style={{color: `var(--accent)`}}>
                <FormattedMessage
                    id={`SECTION_NAME_${section.sectionType}`}
                />
            </div>
        </motion.div>
    }, [sections, menuHeight, selectedSection]);

    return <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{ease: "circOut", duration: 0.3}}
        style={{
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            alignItems: 'center',
            top: 0, left: 0, bottom: 0, right: 0,
            backgroundColor: 'var(--background-alpha-09)',
        }}>
        <div
            onClick={onClose}
            style={{
                top: 0, left: 0, bottom: 0, right: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                position: 'absolute',
            }}
        />
        <div
            style={{
                zIndex: 99,
                position: "absolute",
                display: "flex",
                alignItems: 'center',
                flexDirection: "column",
                maxWidth: 1000,
                width: "100%",
            }}>
            <div onClick={onClose} style={{width: '100%', height: menuHeight}}></div>
            <div
                style={{
                    alignSelf: 'stretch',
                    display: "flex",
                    justifyItems: 'stretch',
                    alignItems: 'flex-end',
                    marginBottom: "1.5vh",
                    paddingLeft: gap,
                    paddingRight: gap,
                }}
            >
                {
                    sections.map((section, index) => {
                        return section.sectionType !== "mainpage" ? menuSection(section, index) : null
                    })
                }
            </div>
            {weddingsLinks}
        </div>
    </motion.div>
};
