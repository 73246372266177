import React, {useEffect, useMemo, useState} from "react";
import {Photo} from "../shared/models/models";
import {motion} from "framer-motion";

const Color = require('color');

export interface IProps {
    photo: Photo
    height?: number
    sessionId: string;
    title?: string;
}

export const borders = 0;
export const margins = 0;

export const FeedPhoto: React.FC<IProps> = (props) => {
    const {photo, height, sessionId, title} = props;
    const [loaded, setLoaded] = useState(false);
    // const backgroundColor = useMemo(() => {
    //     return getComputedStyle(document.documentElement).getPropertyValue('--background').replace(/ /g, '');
    // }, []);
    const width = useMemo(() => {
        return height ? photo.width * height / photo.height : photo.width;
    }, [height]);

    return <motion.div
        initial={{opacity: 0, y: 20}}
        animate={{opacity: loaded ? 1 : 0, y: loaded ? 0 : 20}}
        transition={{ease: "easeOut", duration: 0.6}}
        key={photo.url + sessionId} style={{
        height: height ? height : photo.height,
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'white',
        marginInlineEnd: height ? margins : 0,
    }}>
        <motion.img
            onLoad={
                () => {
                    setLoaded(true);
                }
            }
            style={{height: (height ? height : photo.height) - borders * 2, width: width - borders * 2}}
            key={photo.url + sessionId} src={photo.url}/>
        {title ? <div className={"text-selection-menu-photo-title"} style={{
            paddingTop: 10,
            paddingBottom: 10,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: `var(--background)`,
            textAlign: "center"
        }}>{title}</div> : null}
    </motion.div>
}
