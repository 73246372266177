export type Section = "weddings" | "mainpage" | "lovestory";

export const allSections: Section[] = ["weddings", "lovestory", "mainpage"];

export interface PhotoSession {
    section?: string
    info: string
    name: string
    id?: string
    photos?: Photo[];
}

export interface Photo {
    orderIndex: number
    width: number
    height: number
    url: string
    id?: string
    fileName?: string
    uploaded: boolean
    cover?: boolean
}
