import React from "react";
import {LanguageSelector} from "./LanguageSelector";

export const BottomMenu: React.FC = () => {
    const year = new Date().getFullYear();

    return <div style={{
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: 'center',
        alignItems: "center",
        display: "flex"
    }}>
        <div className={'text-bottom-menu'}>{year}</div>
        <div className={'text-bottom-menu'} style={{paddingLeft: 10, paddingRight: 10}}>|</div>
        <div className={'text-bottom-menu'}>Cyprus</div>
        <div className={'text-bottom-menu'} style={{paddingLeft: 10, paddingRight: 10}}>|</div>
        <LanguageSelector/>
    </div>
}
