import {motion} from "framer-motion";
import React from "react";
import {FormattedMessage} from "react-intl";
import {BottomMenu} from "../components/BottomMenu";
import {useWindowSize} from "../hooks/useWindowSize";

export const About: React.FC = () => {
    const {width} = useWindowSize();

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "space-between",
        paddingInlineStart: "5vw",
        paddingInlineEnd: "5vw",
        height: '100%'
    }}>
        <div style={{
            paddingBottom: "3vh",
            paddingTop: "3vh",
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 1000,
        }}>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .2}}
                className={"text-about-title"}>
                <FormattedMessage
                    id="ABOUT_TITLE"
                />
            </motion.div>
            <div style={{
                display: 'flex',
                flexDirection: width < 950 ? 'column' : 'row',
                paddingTop: "3vh",
                alignItems: 'center',
            }}>
                <motion.img
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{ease: "circOut", duration: 0.6, delay: .3}}
                    style={{height: 'auto', maxWidth: "100%"}}
                    src={"/aboutImage.jpg"} srcSet={"/aboutImage@2x.jpg 2x, /aboutImage@3x.jpg 3x"}/>
                <div style={{width: 20, height: 20, flexShrink: 0}}/>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{ease: "circOut", duration: 0.6, delay: .4}}
                    className={"text-about-body"}>
                    <FormattedMessage
                        id="ABOUT_BODY"
                    />
                </motion.div>
            </div>
        </div>
        <BottomMenu/>
    </div>
}
