import {useMemo, useState} from "react";
import English from '../locale/en/no_filename.json'
import Russian from '../locale/ru/no_filename.json'
import {localeStore} from "../stores/localeStore";
import {useStore} from "react-stores";

export const locales = ['en', 'ru'];

export function useLocale() {
    const {locale} = useStore(localeStore);

    const setLocale = (locale: string) => {
        localeStore.setState({locale});
    }

    const messages = useMemo(() => {
        if (locale === 'ru') {
            return Russian;
        } else {
            return English;
        }
    }, [locale])

    return {locale, setLocale, messages};
};
