import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useStore} from "react-stores";
import Gallery, {PhotoProps} from "react-photo-gallery";
import {store} from "../shared/stores/store";
import {PhotoSession} from "../shared/models/models";
import {BottomMenu} from "../components/BottomMenu";
import {FeedPhoto} from "../components/FeedPhoto";
import {useScreenSize} from "../hooks/useScreenSize";
import {ScreenSize} from "../misc";
import {motion} from "framer-motion";

export const IProps = {}

const gap = 8;

export const GalleryPage: React.FC = () => {
    const params = useParams();
    const [images, setImages] = useState<PhotoProps[]>([]);
    const [session, setSession] = useState<PhotoSession>();
    const sessionId = params.sessionId;
    const section = params.section;
    const [isLoading, setIsLoading] = useState(true);
    const {sections} = useStore(store);
    const screenSize = useScreenSize();

    const numOfColumns = useMemo(() => {
        switch (screenSize) {
            case ScreenSize.Mobile: {
                return 1;
            }
            case ScreenSize.Large: {
                return 3;
            }
            default:
            case ScreenSize.Small: {
                return 2;
            }
        }
    }, [screenSize])

    useEffect(() => {
        if (sections.length) {
            const photos: PhotoProps[] = [];
            sections.forEach((r) => {
                if (r.sectionType === section) {
                    r.sessions.forEach((r) => {
                        if (r.id === sessionId) {
                            setSession(r);
                            r.photos?.forEach((r) => {
                                photos.push({src: r.url, width: r.width, height: r.height});
                            });
                        }
                    });
                }
            });
            setImages(photos);
            setIsLoading(false);
        }
    }, [sessionId, section, sections])

    return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        {
            isLoading ?
                <div style={{position: 'absolute', width: "100px", height: "100px"}}/> : null
        }
        <div style={{display: "flex", flexDirection: "column", paddingBottom: "1.5vh", paddingTop: "1.5vh"}}>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .2}}
                className={"text-session-title"} style={{paddingInlineStart: "5vw", textAlign: "start"}}>
                {session?.name}
            </motion.div>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{ease: "circOut", duration: 0.6, delay: .3}}
                className={"text-session-info"}
                style={{paddingInlineStart: "5vw", textAlign: "start", paddingTop: "1vh"}}>
                {session?.info}
            </motion.div>
        </div>
        <div style={{paddingInlineStart: gap, paddingInlineEnd: gap, paddingBottom: "5vh"}}>
            {
                images.length ?
                    <Gallery margin={gap} columns={numOfColumns} direction={"column"} photos={images}
                             renderImage={(p) => {
                                 return <div key={p.photo.src}
                                             style={{position: "absolute", top: p.top, left: p.left! + gap}}>
                                     <FeedPhoto
                                         height={p.photo.height}
                                         sessionId={sessionId!} photo={{
                                         url: p.photo.src,
                                         height: p.photo.height,
                                         width: p.photo.width,
                                         orderIndex: p.index,
                                         uploaded: true
                                     }}/></div>
                             }
                             }/> : null
            }
        </div>
        <BottomMenu/>
    </div>
}
