import React, {useMemo, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";
import {AiOutlineCloseCircle, AiOutlineInstagram, AiOutlineMenu} from 'react-icons/ai';
import {FormattedMessage} from "react-intl";
import {Separator} from "./Separator";

const Color = require('color');

const iconsSize = 24;

export interface IProps {
    collapsed: boolean;
    height: number;
    scrollInset: number;
    onPortfolioClick: () => void;
    onLocaleSelected: (locale: string) => void;
}

export const TopMenu: React.FC<IProps> = (props) => {
    const {height, scrollInset, onPortfolioClick, collapsed} = props;
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    // const backgroundColor = useMemo(() => {
    //     return getComputedStyle(document.documentElement).getPropertyValue('--background').replace(/ /g, '');
    // }, []);
    //
    // const accentColor = useMemo(() => {
    //     return getComputedStyle(document.documentElement).getPropertyValue('--accent').replace(/ /g, '');
    // }, []);

    const instagram = useMemo(() => {
        return <a
            style={{
                justifyContent: 'center',
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
                display: "flex"
            }}

            href={'https://www.instagram.com/mariajanestudio/'}
            target={'_blank'}
            rel={'noreferrer'}
        >
            <AiOutlineInstagram size={30} color={`var(--accent)`}/>
        </a>
    }, []);

    const home = useMemo(() => {
        return <Link
            className={"text-menu"}
            onClick={
                () => {
                    if (mobileMenuOpened) {
                        setMobileMenuOpened(false);
                    }
                }
            } to="/">
            <FormattedMessage
                id="NAVIGATION_HOME"
            />
        </Link>
    }, [mobileMenuOpened]);

    const work = useMemo(() => {
        return <Link className={"text-menu"} onClick={() => {
            if (mobileMenuOpened) {
                setMobileMenuOpened(false);
            }
            onPortfolioClick();
        }} to="#">
            <FormattedMessage
                id="NAVIGATION_WORK"
            />
        </Link>
    }, [mobileMenuOpened]);

    const contacts = useMemo(() => {
        return <Link
            className={"text-menu"}
            onClick={
                () => {
                    if (mobileMenuOpened) {
                        setMobileMenuOpened(false);
                    }
                }
            }
            to="/contacts">
            <FormattedMessage
                id="NAVIGATION_CONTACTS"
            />
        </Link>
    }, [mobileMenuOpened])

    const about = useMemo(() => {
        return <Link
            className={"text-menu"}
            onClick={
                () => {
                    if (mobileMenuOpened) {
                        setMobileMenuOpened(false);
                    }
                }
            }
            to="/about">
            <FormattedMessage
                id="NAVIGATION_ABOUT"
            />
        </Link>
    }, [mobileMenuOpened])

    const menuCollapsed = useMemo(() => {
        return <div style={{
            top: 0, bottom: 0, left: 0, right: 0,
            position: 'absolute',
            overflow: "hidden"
        }}>
            <AnimatePresence initial={false}>
                <motion.div
                    key={"menu_opened"}
                    animate={{
                        opacity: mobileMenuOpened ? 0 : 1,
                        scale: mobileMenuOpened ? 0.7 : 1,
                    }}
                    transition={{
                        ease: mobileMenuOpened ? "easeIn" : "easeOut",
                        duration: 0.15,
                        delay: mobileMenuOpened ? 0.0 : 0.1
                    }}
                    style={{
                        top: 0, bottom: 0, left: 0, right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "space-between",
                        paddingLeft: "6vw", paddingRight: "6vw"
                    }}>
                    {instagram}
                    <Link style={{
                        objectFit: "cover",
                        display: "flex"
                    }} to="/">
                        <img style={{width: 90}} src={"/mainLogoHorizontal.svg"}/>
                    </Link>
                    <Link style={{
                        justifyContent: 'center',
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }} onClick={() => {
                        setMobileMenuOpened(true);
                    }} to="#">
                        <AiOutlineMenu size={iconsSize} color={`var(--accent)`}/>
                    </Link>
                </motion.div>
                <motion.div
                    transition={{
                        ease: mobileMenuOpened ? "easeOut" : "easeIn",
                        duration: 0.15,
                        delay: mobileMenuOpened ? 0.1 : 0.0
                    }}
                    animate={{
                        opacity: mobileMenuOpened ? 1 : 0,
                        scale: mobileMenuOpened ? 1 : 1.3,
                    }}
                    style={{
                        pointerEvents: mobileMenuOpened ? "auto" : "none",
                        top: 0, bottom: 0, left: 0, right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "space-between",
                        paddingLeft: "6vw", paddingRight: "6vw"
                    }}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                        {home}
                        <Separator/>
                        {work}
                        <Separator/>
                        {about}
                        <Separator/>
                        {contacts}
                    </div>
                    <Link style={{
                        justifyContent: 'center',
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }} onClick={() => {
                        setMobileMenuOpened(false);
                    }} to="#">
                        <AiOutlineCloseCircle size={iconsSize} color={`var(--accent)`}/>
                    </Link>
                </motion.div>
            </AnimatePresence>
        </div>;
    }, [height, scrollInset, onPortfolioClick, mobileMenuOpened]);

    const menuNormal = useMemo(() => {
        return <div style={{
            top: 0, bottom: 0, left: 0, right: 0,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <div style={{
                width: '100%',
                justifyContent: 'end',
                display: "flex",
                justifyItems: "center",
                alignItems: "center"
            }}>
                {home}
                <Separator/>
                {work}
            </div>
            <Link style={{
                objectFit: "cover",
                left: "50%",
                top: "50%",
                alignSelf: "center",
                justifySelf: "center",
                paddingLeft: "2vw",
                paddingRight: "2vw",
                display: "flex"
            }} to="/">
                <img style={{width: 130}} src={"/mainLogoHorizontal.svg"}/>
            </Link>
            <div style={{
                width: '100%', display: "flex", justifyItems: "center",
                alignItems: "center"
            }}>
                {about}
                <Separator/>
                {contacts}
            </div>
        </div>
    }, [height, scrollInset, onPortfolioClick]);

    return <div style={{
        position: 'absolute',
        width: '100%',
        height: height
    }}>
        <motion.div
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'var(--background)',
            }}
            animate={{opacity: scrollInset > 0 ? 1 : 0}}
            transition={{ease: "easeInOut", duration: 0.2}}>
        </motion.div>
        {collapsed ? menuCollapsed : menuNormal}
    </div>
}
