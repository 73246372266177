import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./routes/Home";
import {GalleryPage} from "./routes/GalleryPage";
import {DataManager} from "./shared/managers/DataManager";
import {PhotoSession} from "./shared/models/models";
import {SessionsSelectionMenu} from "./components/SessionsSelectionMenu";
import {TopMenu} from "./components/TopMenu";
import {useScreenSize} from "./hooks/useScreenSize";
import {ScreenSize} from "./misc";
import {useLocale} from "./hooks/useLocale";
import {IntlProvider} from "react-intl";
import {About} from "./routes/About";
import {Contacts} from "./routes/Contacts";

const MENU_HEIGHT_NORMAL = 90;
const MENU_HEIGHT_COLLAPSED = 80;

export const dataManager = new DataManager();

function App() {
    const [scrollInset, setScrollInset] = useState(0);
    const [sessionsMenuVisible, setSessionsMenuVisible] = useState(false);
    const screenSize = useScreenSize();
    const {locale, messages} = useLocale();

    const menuCollapsed = useMemo(() => {
        return screenSize === ScreenSize.Mobile;
    }, [screenSize])

    const menuHeight = useMemo(() => {
        return menuCollapsed ? MENU_HEIGHT_COLLAPSED : MENU_HEIGHT_NORMAL;
    }, [menuCollapsed])

    useEffect(() => {
        dataManager.refreshPhotoSets().then();
    }, []);

    return (
        <IntlProvider messages={messages} locale={locale}>
            <BrowserRouter>
                <div
                    onScroll={(e) => {
                        setScrollInset(e.currentTarget.scrollTop);
                    }}
                    style={{
                        position: "absolute",
                        flex: 1,
                        paddingTop: menuHeight,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: 'auto'
                    }}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/session">
                            <Route path=":section/:sessionId" element={<GalleryPage/>}/>
                        </Route>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/contacts" element={<Contacts/>}/>
                    </Routes>
                </div>
                <TopMenu
                    onLocaleSelected={(locale) => {
                    }}
                    collapsed={menuCollapsed}
                    height={menuHeight}
                    scrollInset={scrollInset}
                    onPortfolioClick={() => {
                        setSessionsMenuVisible(true);
                    }}/>
                {sessionsMenuVisible ?
                    <SessionsSelectionMenu
                        menuHeight={menuHeight}
                        onClose={() => setSessionsMenuVisible(false)}/> : null}
            </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
