import {Store} from "react-stores";
import {PhotoSession, Section} from "../models/models";

export interface ISectionStorage {
    sectionType: Section,
    sessions: PhotoSession[]
}

export interface IStoreState {
    sections: ISectionStorage[];
}

export const store = new Store<IStoreState>({sections: []});
