import {useEffect, useState} from "react";
import {ScreenSize} from "../misc";
import {useWindowSize} from "./useWindowSize";

export function useScreenSize() {
    const {width} = useWindowSize();
    const [screenSize, setScreenSize] = useState(ScreenSize.Small);

    useEffect(() => {
        let screenSize;

        if (width <= ScreenSize.Mobile) {
            screenSize = ScreenSize.Mobile;
        } else if (width <= ScreenSize.Small) {
            screenSize = ScreenSize.Small;
        } else {
            screenSize = ScreenSize.Large;
        }

        setScreenSize(screenSize)
    }, [width])

    return screenSize;
}

