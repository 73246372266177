import React, {useMemo} from "react";
import {locales, useLocale} from "../hooks/useLocale";

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const LanguageSelector: React.FC = () => {
    const {locale, setLocale} = useLocale();

    const selectors = useMemo(() => {
        return locales.map((r, index) => {
            return <a key={r}
                      style={{cursor: "pointer"}}
                      onClick={() => {
                          setLocale(r)
                      }}
                      className={"text-bottom-menu"}>
                {capitalizeFirstLetter(r)}
                {index !== locales.length - 1 ? "/" : null}
            </a>
        });
    }, [locale]);

    return <div style={{
        justifyContent: 'center',
        alignItems: "center",
        display: "flex"
    }}>
        {selectors}
    </div>
}
